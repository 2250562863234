<template>
    <select
        v-model="selected"
        class="block w-full rounded-lg border border-gray-300 bg-white p-4 text-base text-grind"
    >
        <option value="">Select a section</option>
        <option v-for="section in sections" :key="slugify(section.id)">
            {{ section.heading }}
        </option>
    </select>
</template>

<script setup lang="ts">
import { slugify } from '~/utils/helpers';
import TableOfContentsSectionType from '~/types/TableOfContentsSectionType';
import { ref } from 'vue';

const selected = ref(null);

defineProps<{
    sections: TableOfContentsSectionType[];
}>();

watch(
    () => selected.value,
    () => {
        window.location.href = '#' + slugify(selected.value);
    },
);
</script>
